body, html {
  height: 100%;
  margin: 0;
  font-family: Arial, sans-serif;
}

.container {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.left-section, .right-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.left-section {
  flex: 2;
  background-color: #282a36;
  color: white;
  padding: 20px;
}

.right-section {
  flex: 1;
  background-color: #00BABC;
  color: white;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.logo-placeholder {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
}

.verification-process {
  max-width: 80%;
}

.elements-list {
  list-style: none;
  padding: 0;
}

.element-item {
  background-color: #32354A;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.element-name {
  flex-grow: 1;
}

.download-button {
  background-color: #00BABC;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.download-button:hover {
  background-color: #009DA9;
}

.metamask-message {
  color: #FFC107; /* Using a warning color to draw attention */
}
